body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flipVideo {
  display: none;
}

.statsPanel {
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0.9;
  z-index: 10000;
}

.videoPaused {
  position: absolute;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: gray;
  opacity: 0.7;
}

.gamePage {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.puzzleCardsContainer {
  margin: 20px;
}

.card {
  transition: all 1s;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);
  border-radius: 30px;
  line-height: 0;
  position: absolute;
  box-sizing: border-box;
}

.card.selected {
  box-shadow: 0 12px 16px 0 rgba(99, 6, 6, 0.9);
  border: 1px solid red;
}

.poseIndicator {
  text-align: center;
  margin-top: 4em;
}

.timer {
  margin-top: 307px;
  padding-left: 80px;
  padding-right: 80px;
}

.expectedCards {
  border: 10px dotted rgb(0, 165, 8);
  border-radius: 30px;
}

.cardsNotInOrder {
  border: 10px dotted transparent;
  border-radius: 30px;
}

.faceCanvas {
  position: absolute;
  left: 280px;
  top: 530px;
}
