.flipVideo {
  display: none;
}

.statsPanel {
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0.9;
  z-index: 10000;
}

.videoPaused {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: gray;
  opacity: 0.7;
}

.gamePage {
  display: flex;
  align-items: flex-start;
}

.puzzleCardsContainer {
  margin: 20px;
}

.card {
  transition: all 1s;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);
  border-radius: 30px;
  line-height: 0;
  position: absolute;
  box-sizing: border-box;
}

.card.selected {
  box-shadow: 0 12px 16px 0 rgba(99, 6, 6, 0.9);
  border: 1px solid red;
}

.poseIndicator {
  text-align: center;
  margin-top: 4em;
}

.timer {
  margin-top: 307px;
  padding-left: 80px;
  padding-right: 80px;
}

.expectedCards {
  border: 10px dotted rgb(0, 165, 8);
  border-radius: 30px;
}

.cardsNotInOrder {
  border: 10px dotted transparent;
  border-radius: 30px;
}

.faceCanvas {
  position: absolute;
  left: 280px;
  top: 530px;
}